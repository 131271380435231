import styled from "styled-components"

export const Text = styled.p`
  color: #777;
  margin-bottom: 0;
  font-weight: 600;
`

export const Value = styled.p`
  margin-top: 0;
  a {
    color: white;
  }
`
