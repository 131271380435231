import styled from "styled-components"
import myTheme from "../../../theme"

export const Container = styled.ul`
  display: none;
  color: white;
  flex-direction: column;
  list-style: none;
  padding-left: 0;

  svg {
    cursor: pointer;
  }

  @media (max-width: ${myTheme.responsive.container}) {
    display: flex;
  }
`
