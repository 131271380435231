import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Button } from "@material-ui/core"

import { Container, BackgroundOverlay, NormalBackground } from "./styles"

interface HeroProps {
  title: string
  description?: string
  button?: IButtonHeroProps
  fluidImage: any
}

interface IButtonHeroProps {
  text: string
  color: "primary" | "secondary"
  link: string
}

const Hero = ({ title, description, button, fluidImage }: HeroProps) => {
  return (
    <NormalBackground image={fluidImage.base64}>
      <BackgroundOverlay />
      <Container>
        <h1>{title.split("\\n").map((result:String) =>`${result}\n`)}</h1>
        {description && <h3>{description.split("\\n").map((result:String) =>`${result}\n`)}</h3>}
        {button && (
          <AnchorLink to={button.link}>
            <Button variant="contained" color={button.color}>
              {button.text}
            </Button>
          </AnchorLink>
        )}
      </Container>
    </NormalBackground>
  )
}

export default Hero
