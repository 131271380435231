import styled from "styled-components"
import myTheme from "../../../theme"

export const Container = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 999;

  svg {
    color: #25D366;
    background-color: white;
    border-radius: 10px;
    font-size: 52px;
  }

  @media (max-width: ${myTheme.responsive.container}) {
  }
`
