import React, { useState } from "react"
import { FaAngleDown, FaAngleUp, FaBars } from "react-icons/fa"
import { navigate } from "gatsby"
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
} from "@material-ui/core"
import Image from "gatsby-image"

import { Container } from "./styles"
import { IMenuItems } from "../Menu"

interface DrawerMenuProps {
  menuItems: IMenuItems[]
  logoImage: any
  button?: IButtonDrawerProps
  id?: string
}

interface IButtonDrawerProps {
  text: string
  link?: string
  external?: boolean
}

const DrawerMenu = ({ menuItems, logoImage, button, id }: DrawerMenuProps) => {
  const initialDropdownStatus = menuItems.map(item => false)

  const [open, setOpen] = useState(false)
  const [dropdownStatus, setDropdownStatus] = useState(initialDropdownStatus)

  const handlerDrawer = () => {
    setOpen(!open)
  }

  const handlerDropdown = (index: any) => {
    const updatedDropdownStatus = [...dropdownStatus]
    updatedDropdownStatus[index] = !dropdownStatus[index]
    setDropdownStatus(updatedDropdownStatus)
  }

  return (
    <Container>
      <FaBars size={22} onClick={handlerDrawer} />
      <Drawer id={id} anchor="right" open={open} onClose={() => setOpen(false)}>
        <div>
          {logoImage && <Image fluid={logoImage} />}
          <List>
            {menuItems &&
              menuItems.map((menuItem, index) =>
                menuItem.submenu ? (
                  <div key={index}>
                    <ListItem
                      button
                      onClick={() => handlerDropdown(index)}
                      className={dropdownStatus[index] ? "active" : ""}
                    >
                      <ListItemText primary={menuItem.text} />
                      {dropdownStatus[index] ? (
                        <FaAngleUp />
                      ) : (
                        <FaAngleDown />
                      )}
                    </ListItem>
                    <Collapse in={dropdownStatus[index]}>
                      {menuItem.submenu.map((submenuItem, index) => (
                        <ListItem
                          className="submenu"
                          button
                          key={index}
                          onClick={() => navigate(submenuItem.link || "/")}
                        >
                            <ListItemText primary={submenuItem.text} />
                        </ListItem>
                      ))}
                    </Collapse>
                  </div>
                ) : (
                  <ListItem
                    button
                    key={index}
                    onClick={() => navigate(menuItem.link || "/")}
                  >
                      <ListItemText primary={menuItem.text} />
                  </ListItem>
                )
              )}
          </List>
          <Divider />
          <div className="btn-container">
            {button && button.external ? (
              <a href={button.link}>
                <Button variant="contained" color="primary">
                  {button.text}
                </Button>
              </a>
            ) : (
              <Button
                onClick={() => navigate(button.link)}
                variant="contained"
                color="primary"
              >
                {button.text}
              </Button>
            )}
          </div>
        </div>
      </Drawer>
    </Container>
  )
}

export default DrawerMenu
