export default {
  colors: {
    primary: "#e00101",
    secondary: "#fff",
    dark: "#222",
    secondaryBG: "#f4f4f4",
  },
  fontSize: {
    body: "16px",
  },
  fontFamily: "Roboto, sans-serif",
  responsive: {
    container: "860px",
  },
}
