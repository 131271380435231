import React from "react"

import { Text, Value } from "./styles"

interface TopicProps {
  text: string
  value: string
  link?: string
}

const Topic: React.FC<TopicProps> = ({ children, text, value, link, ...rest }) => {
  return (
    <div {...rest}>
      <Text>{text}</Text>
      <Value>
        <a href={link}>{value}</a>
      </Value>
    </div>
  )
}

export default Topic
