import styled from "styled-components"

import overlay from "../../../images/details/overlay.png"
import myTheme from "../../../theme"

interface NormalBackgroundProps {
  image: string;
}

export const NormalBackground = styled.div<NormalBackgroundProps>`
  height: 480px;
  display: flex;
  padding: 0 30px;
  background-image: url(${(props) => props.image});
  background-position: top center;
  background-size: cover;

  justify-content: center;

  @media(max-width: ${myTheme.responsive.container}){
    height: 100vh;
    max-height: 700px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;

  height: 100%;
  width: 1200px;
  padding: 0 30px;

  h1 {
    color: white;
    font-size: 55px;
    line-height: 1.2em;
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    white-space: pre-wrap;
    max-width: 820px;
  }

  h3 {
    color: white;
    font-size: 24px;
    max-width: 820px;
    -webkit-font-smoothing: antialiased;
    font-weight: 900;
    white-space: pre-wrap;
    margin-top: 0;
  }

  @media (max-width: ${myTheme.responsive.container}) {
    padding: 0;
    height: 100vh;
    max-height: 700px;

    h1 {
      font-size: 36px;
      line-height: 1.2em;
    }

    h3 {
      font-size: 18px;
    }

  }
`

export const BackgroundOverlay = styled.div`
  height: 480px;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 0;

  background-image: url(${overlay});
  background-repeat: repeat;
  background-size: auto;
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;

  @media(max-width: ${myTheme.responsive.container}){
    height: 100vh;
    max-height: 700px;
  }
`
