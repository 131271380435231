import styled from "styled-components"

import myTheme from "../../../theme"

export const Container = styled.div`
  position: absolute;
  top: 0;
  z-index: 99;

  background-color: transparent;
  width: 100%;
  padding: 0 60px;

  span {
    cursor: pointer;
  }

  nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding-top: 15px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    button {
      font-weight: 600;
    }

    .gatsby-image-wrapper {
      width: 130px;
      height: 25px;
    }
  }

  @media (max-width: ${myTheme.responsive.container}) {
    padding: 0 30px;
    nav button {
      display: none;
    }
  }
`
