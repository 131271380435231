import React from "react"
import { FaWhatsappSquare } from "react-icons/fa"

import { Container } from "./styles"

const IconText: React.FC = ({ ...rest }) => {
  return (
    <a href="https://wa.me/55027996433420" target="_blank">
      <Container {...rest}>
        <FaWhatsappSquare />
      </Container>
    </a>
  )
}

export default IconText
