import React from "react"
import { FaAngleDown } from "react-icons/fa"
import { Link } from "gatsby"

import { Container } from "./styles"

interface MenuProps {
  menuItems: IMenuItems[]
}

export interface IMenuItems {
  text?: string
  link?: string
  submenu?: IMenuItems[]
}

const Menu = ({ menuItems }: MenuProps) => {
  return (
    <Container>
      {menuItems &&
        menuItems.map((menuItem, index) => (
          <li key={index}>
            {!menuItem.submenu && (
              <Link to={menuItem.link || "/"}>{menuItem.text}</Link>
            )}
            {menuItem.submenu && (
              <span className="dropdown">
                <span className="submenu">
                  <a> {menuItem.text} </a>
                  <FaAngleDown size={22} />
                </span>
                <ul className="dropdown-content">
                  {menuItem.submenu.map((submenuItem, index) => (
                    <Link to={submenuItem.link || "/"} key={index}>
                      <li>
                        {submenuItem.text}
                      </li>
                    </Link>
                  ))}
                </ul>
              </span>
            )}
          </li>
        ))}
    </Container>
  )
}

export default Menu
