import React from "react"
import { CssBaseline, ThemeProvider, createMuiTheme } from "@material-ui/core"
import { useStaticQuery, graphql, Link } from "gatsby"

import { GlobalStyle, theme } from "../components/styles.global"

import Header from "../components/Organisms/Header"
import Footer, { IFooterMenuItems } from "../components/Organisms/Footer"
import SocialIcons from "../components/Molecules/SocialIcons"
import Topic from "../components/Atoms/Topic"
import WhatsappBtn from "../components/Atoms/WhatsappBtn"

const Main = ({ children }) => {
  const {
    logo: {
      childImageSharp: { fluid: logo },
    },
    logoAlternative: {
      childImageSharp: { fluid: logoAlternative },
    },
    allMarkdownRemark: { edges: slugsEdges }
  } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "logo/logo-white.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        logoAlternative: file(relativePath: { eq: "logo/logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        },
        allMarkdownRemark {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                name
              }
            }
          }
        }
      }
    `
  )

  const materialTheme = createMuiTheme(theme)

  const submenuContents = slugsEdges.map(({ node: { fields, frontmatter } }) => {
    return {
      link: fields.slug,
      text: frontmatter.name,
      section: fields.slug.split("/")[1]
    }
  })

  const submenuQuemServimos = submenuContents.filter(item => item.section === "quem-servimos")
  const submenuServicos = submenuContents.filter(item => item.section === "servicos")

  const footerMenuItems: IFooterMenuItems[] = [
    {
      title: "quem servimos",
      Component: () => (
        <ul>
          {submenuQuemServimos && submenuQuemServimos.map((item, index) => (
            <li key={index}>
              <Link to={`${item.link}`}>{item.text}</Link>
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: "serviços",
      Component: () => (
        <ul>
          {submenuServicos && submenuServicos.map((item, index) => (
            <li key={index}>
              <Link to={`${item.link}`}>{item.text}</Link>
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: "contato",
      Component: () => (
        <div>
          <Topic text="Telefone:" value="(27) 99643-3420" link="tel:+55027996433420" />
          <Topic text="Email:" value="contato@agenciapaladino.com.br" link="mailto:contato@agenciapaladino.com.br" />
        </div>
      ),
    },
    {
      title: "redes sociais",
      Component: () => <SocialIcons />,
    },
  ]

  return (
    <div>
      <CssBaseline />
      <GlobalStyle />
      <ThemeProvider theme={materialTheme}>
        <Header
          menuItems={[
            { text: "home" },
            {
              text: "quem servimos",
              submenu: submenuQuemServimos
            },
            {
              text: "serviços",
              submenu: submenuServicos,
            },
            { text: "sobre", link: "/sobre/" },
            { text: "contato", link: "/contato/" },
          ]}
          button={{
            text: "(27) 99643-3420",
            link: "tel:027996433420",
            external: true,
          }}
          logoImage={logo}
          logoImageAlternative={logoAlternative}
        />
        {children}
        <Footer footerMenuItems={footerMenuItems} />
        <WhatsappBtn />
      </ThemeProvider>
    </div>
  )
}
export default Main
