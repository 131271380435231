import { createGlobalStyle } from "styled-components"
import myTheme from "../theme"

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: #fff;
    font-family: ${myTheme.fontFamily};
    font-size: ${myTheme.fontSize.body};
  }

  #nprogress {
    .bar {
      height: 5px;
    }
    .spinner {
      z-index: 1300 !important;
    }
  }

  a {
    text-decoration: none;
  }

  #top-menu .MuiPaper-root {
    min-width: 250px;
    text-transform: capitalize;
    padding-top: 20px;
    background-color: #fafafb;

    ul {
      .MuiListItem-button:hover {
        background-color: ${myTheme.colors.primary};
        color: white;
      }

      .submenu {
        padding-left: 30px;
      }
    }

    img, .gatsby-image-wrapper {
      width: 130px !important;
      height: auto !important;
      margin: auto;
    }

    .btn-container {
      margin-top: 20px;
      width: 100%;
      text-align: center;
    }
  }
`

export const theme = {
  palette: {
    primary: {
      main: myTheme.colors.primary,
    },
    secondary: {
      main: myTheme.colors.secondary,
    },
  },
}
