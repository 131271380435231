import styled from "styled-components"
import myTheme from "../../../theme"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`

export const IconWrapper = styled.div`
  margin: 0 5px;
  border: solid 1px #888;
  padding: 10px 13px 8px 13px;

  svg {
    font-size: 16px;
    color: white;
  }
`
