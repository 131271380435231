import styled from "styled-components"
import myTheme from "../../../theme"

export const Container = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;

  list-style: none;
  padding-left: 0;

  li {
    padding: 0 20px;

    span.submenu {
      display: flex;
      color: white;
      cursor: pointer;
      margin-right: -20px;
    }

    a {
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      text-decoration: none;
    }
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: ${myTheme.colors.primary};
    min-width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 0;
    z-index: 1;
    list-style-type: none;
    border-top: solid 3px white;

    a {
      width: 100%;
      text-transform: capitalize;
      cursor: pointer;

      li {
        padding: 12px 16px;
      }

      &:hover  {
        color: ${myTheme.colors.primary};
        background-color: white;
      }
    }
  }

  .dropdown:hover .dropdown-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: ${myTheme.responsive.container}) {
    display: none;
  }
`
