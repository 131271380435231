import React from "react"
import { FaFacebookSquare, FaInstagramSquare, FaYoutube } from "react-icons/fa"

import { Container, IconWrapper } from "./styles"

const Component: React.FC = ({ ...rest }) => {
  const iconComponents = [
    {
      Icon: FaFacebookSquare,
      link: "https://www.facebook.com/AgenciaPaladinoOficial/"
    },
    {
      Icon: FaInstagramSquare,
      link: "https://www.instagram.com/agenciapaladino/"
    },
    {
      Icon: FaYoutube,
      link: "https://www.youtube.com/channel/UCu6O3ZCJ1szw5vW7z7vBLGg"
    }
  ]
  return (
    <Container {...rest}>
      {iconComponents && iconComponents.map(({ Icon, link }, index) => (
        <a href={link} target="_blank" key={index}>
          <IconWrapper>
            <Icon />
          </IconWrapper>
        </a>
      ))}
    </Container>
  )
}

export default Component
