import React from "react"

import { Container } from "./styles"

interface FooterProps {
  footerMenuItems?: IFooterMenuItems[]
}

export interface IFooterMenuItems {
  title: string
  Component: () => JSX.Element
}

const Footer: React.FC<FooterProps> = ({ footerMenuItems, ...rest }) => {
  return (
    <Container {...rest}>
      <div className="footer">
        {footerMenuItems && footerMenuItems.map(({ title, Component }, index) => (
          <div className="footer-collumn" key={index}>
            <h3>{title}</h3>
            <Component />
          </div>
        ))}
      </div>
    </Container>
  )
}

export default Footer
