import { Button } from "@material-ui/core"
import React from "react"
import Image from "gatsby-image"
import { navigate } from "gatsby"

import { Container } from "./styles"
import Menu, { IMenuItems } from "../../Molecules/Menu"
import DrawerMenu from "../../Molecules/DrawerMenu"

interface HeaderProps {
  button?: IButtonHeaderProps
  menuItems?: IMenuItems[]
  logoImage?: any
  logoImageAlternative?: any
}

interface IButtonHeaderProps {
  text: string
  link?: string
  external?: boolean
}

const Header = ({
  button,
  menuItems,
  logoImage,
  logoImageAlternative,
}: HeaderProps) => {
  const handleButton = (uri: string) => {
    navigate(uri)
  }

  return (
    <Container>
      <nav>
        <span onClick={()=>navigate('/')}><Image fluid={logoImage} /></span>
        <div>
          <Menu menuItems={menuItems} />
          {button && button.external ? (
            <a href={button.link}>
              <Button variant="contained" color="primary">
                {button.text}
              </Button>
            </a>
          ) : (
            <Button
              onClick={() => handleButton(button.link)}
              variant="contained"
              color="primary"
            >
              {button.text}
            </Button>
          )}
          <DrawerMenu
            menuItems={menuItems}
            logoImage={logoImageAlternative}
            button={button}
            id="top-menu"
          />
        </div>
      </nav>
    </Container>
  )
}

export default Header
