import styled from "styled-components"
import myTheme from "../../../theme"

export const Container = styled.div`
  background-color: black;
  padding: 30px;

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    max-width: 1200px;
    margin: auto;
    padding: 30px;

    color: white;

    ul {
      padding-left: 0;
    }

    h3 {
      text-transform: uppercase;
      font-size: 16px;
      border-left: solid 3px ${myTheme.colors.primary};
      padding-left: 10px;
    }

    .footer-collumn {
      display: flex;
      flex-direction: column;
      padding: 0 10px;

      min-width: 20%;
      max-width: 45%;

      li {
        list-style-type: none;
        padding: 5px 0;

        a {
          color: white;
        }
      }
    }
  }

  @media (max-width: ${myTheme.responsive.container}) {
    .footer {
      padding: 0;

      .footer-collumn {
        min-width: 40%;
      }
    }
  }
`
